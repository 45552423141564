import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";

function Mission({data}) {
    return (
        <div className="tekrevol_mission p-100" id="mission">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="mission_title">
                            <h2>{checkData(data, 'mission_title')}</h2>
                            <div
                                dangerouslySetInnerHTML={{__html: checkData(data, 'mission_description')}}/>
                        </div>
                    </Col>
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="text-center m-auto offset-lg-1">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'mission_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'mission_title')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Mission;